import React from 'react';
import { Layout } from '../components/Layout/Layout';
import '@fontsource/homemade-apple';
import { Button, Text } from '@mantine/core';

export default function HomePage() {
  return (
    <Layout>
      <main >
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Text size="lg" style={{ margin: '2rem' }}>
            Oops...nothing to see here
          </Text>
          <Button component="a" href="https://mangolatte.dev">
            Go Back
          </Button>
        </div>
      </main>
    </Layout>
  );
}